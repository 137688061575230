import type {QueryResult} from '@apollo/client'

// PageStatus
export enum QueryStatus {
  LOADING = 'LOADING',
  READY = 'READY',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
  REDIRECT = 'REDIRECT',
}

interface QueryStatusProps<TData, TVariables> {
  queryResult: QueryResult<TData, TVariables>
  isReady?: (data: TData) => boolean
  shouldRedirect?: (data: TData) => boolean
}

export const useQueryStatus = <TData, TVariables>({
  queryResult,
  isReady = data => Boolean(data),
  shouldRedirect,
}: QueryStatusProps<TData, TVariables>): QueryStatus => {
  const {data, error, loading} = queryResult

  if (error) {
    return QueryStatus.ERROR
  }

  // * Either loading or not, the data can be already in the cache.
  // * Moreover we don't want the page to "blink" on polling every 15sec.
  if (isReady(data)) {
    if (shouldRedirect?.(data)) {
      return QueryStatus.REDIRECT
    }
    return QueryStatus.READY
  }

  if (loading) {
    return QueryStatus.LOADING
  }

  // * Not loading && no data.
  return QueryStatus.NOT_FOUND
}
