import {palette} from '@ambler/andive-next'
import React from 'react'
import dynamic from 'next/dynamic'
import moment from '@ambler/moment'
import {range} from 'lodash'

import styled from 'styled-components'

const ApexChart = dynamic(() => import('react-apexcharts'), {ssr: false})

const ChartRoot = styled.div`
  padding-right: 16px;
`

export function BarChart({
  values,
  label,
  average5,
  average7,
}: {
  values: Record<string, number>
  label: string
  average5: number
  average7: number
}) {
  const categories = Object.keys(values)
  const data = categories.map(key => values[key])

  return (
    <ChartRoot>
      <ApexChart
        options={{
          plotOptions: {
            bar: {
              borderRadius: 4,
              columnWidth: '64',
            },
          },
          xaxis: {
            categories,
          },
          colors: [palette.amblea.blue[800]],
          annotations: {
            yaxis: [
              {
                y: average7,
                y2: average5,
                strokeDashArray: 4,
                borderColor: palette.amblea.blue[600],
                fillColor: palette.amblea.blue[600],
                label: {
                  offsetX: -5,
                  offsetY: -5,
                  text: 'Moyennes (jours francs/ouvrés)',
                  style: {
                    background: palette.amblea.blue[600],
                    color: palette.amblea.white,
                  },
                },
              },
            ],
          },
        }}
        series={[{name: label, data}]}
        type="bar"
        width="100%"
        height={240}
      />
    </ChartRoot>
  )
}

const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export function LineChart({values, categories, label}: {values: number[]; categories: string[]; label: string}) {
  const firstYear = 2018
  const currentYear = moment().year()
  const endOfYearWeeks = range(firstYear, currentYear).map(y => `${y} semaine 52`)

  const versions = [
    '2018 semaine 28',
    '2018 semaine 48',
    '2019 semaine 21',
    '2019 semaine 41',
    '2021 semaine 39',
    '2024 semaine 9',
  ]

  const events = [
    {date: '2018-10-15', label: 'A4H', offsetY: -21},
    {date: '2018-11-19', label: 'A4T', offsetY: 0},

    {date: '2019-01-14', label: 'Dispatch auto', offsetY: -40},
    {date: '2019-04-01', label: 'Unbook'},
    {date: '2019-07-01', label: 'Art80 / Sécu', offsetY: -24},
    {date: '2019-11-25', label: 'Nuit du pooling', offsetY: 42},

    {date: '2020-02-17', label: 'BO Tasks', offsetY: -20},
    {date: '2020-03-16', label: 'FCT', offsetY: 40},
    {date: '2020-03-30', label: 'TPS', offsetY: -9},
    {date: '2020-10-19', label: 'Notifs sonores'},

    {date: '2021-01-11', label: "Vérif fil de l'eau", offsetY: 57},
    {date: '2021-03-15', label: 'Change request', offsetY: 45},
    {date: '2021-04-26', label: 'A4LDD'},
    {
      date: '2021-05-31',
      label: 'Raisons transport custo',
      offsetY: -20,
    },
    {date: '2021-08-30', label: 'FTS', offsetY: 2},
    {date: '2021-12-13', label: 'ePMT'},

    {date: '2022-02-14', label: 'Optim', offsetY: 35},
    {date: '2022-03-14', label: 'Réserve'},
    {date: '2022-05-02', label: 'Pinard', offsetY: 40},
    {date: '2022-08-29', label: 'Commande ∞', offsetY: -15},
    {date: '2022-11-28', label: 'Pooling BR', offsetY: 80},
    {date: '2023-01-02', label: 'Dispatch A4H', offsetY: -18},
    {date: '2023-01-18', label: 'BRO', offsetY: 43},
    {date: '2023-03-06', label: 'Factu Amblea', offsetY: -19},
    {date: '2023-05-16', label: 'Planning ALD', offsetY: 45},
    {date: '2024-01-30', label: 'TP AES', offsetY: 2},
    {date: '2024-04-10', label: 'App mobile', offsetY: 60},
  ]

  const colors = React.useMemo(
    () =>
      shuffleArray([
        palette.amblea.blue[800],
        palette.amblea.grey[600],
        palette.amblea.blue[600],
        palette.amblea.tea[600],
        palette.amblea.red[700],
        palette.amblea.green[500],
        palette.amblea.orange[500],
      ]),
    [],
  )

  const points = events.map((event, index) => {
    const color = colors[index % colors.length]
    const formatedDate = moment(event.date).format('YYYY [semaine] W')
    return {
      x: formatedDate,
      y: values[categories.indexOf(formatedDate)],
      marker: {
        size: 3,
        strokeColor: color,
        fillColor: color,
      },
      label: {
        borderColor: color,
        offsetY: event.offsetY ?? -6,
        style: {
          color: color === palette.amblea.orange[500] ? palette.amblea.blue[800] : palette.amblea.white,
          background: color,
        },
        text: event.label,
      },
    }
  })

  const weeklyPassengersHigh = Math.max(...values)

  return (
    <ChartRoot>
      <ApexChart
        options={{
          chart: {toolbar: {tools: {pan: false, zoom: false}}},
          xaxis: {
            tooltip: {
              formatter: val => {
                return categories[parseInt(val) - 1]
              },
              offsetY: 20,
            },
            categories,
            labels: {
              // X-axis annotations for category charts find the x-axis label position in the DOM and then set its position.
              // can't just use "show: false"
              style: {
                fontSize: '0',
              },
            },
          },
          yaxis: {tooltip: {enabled: true}},
          stroke: {
            curve: 'smooth',
            width: 3,
          },
          colors: [palette.amblea.blue[800]],
          tooltip: {custom: () => null},
          annotations: {
            xaxis: [
              ...endOfYearWeeks.map(weekLabel => ({
                x: weekLabel,
                strokeDashArray: 4,
                borderColor: palette.amblea.grey[400],
              })),
              ...versions.map((version, index) => ({
                x: version,
                strokeDashArray: 4,
                borderColor: index === 0 ? palette.amblea.grey[400] : palette.amblea.green[200],
                label: {
                  offsetY: -20,
                  style: {
                    color: palette.amblea.blue[800],
                    background: index === 0 ? palette.amblea.grey[400] : palette.amblea.green[200],
                  },
                  text: `V${index}`,
                  orientation: '1',
                },
              })),
              {
                x: '2023 semaine 26',
                strokeDashArray: 4,
                borderColor: palette.amblea.pink[600],
                borderWidth: 3,
                label: {
                  borderColor: palette.amblea.blue[600],
                  offsetY: -20,
                  style: {
                    color: palette.amblea.blue[600],
                    background: palette.amblea.pink[600],
                  },
                  text: `FUUUUUSION`,
                  orientation: '1',
                  borderWidth: 2,
                },
              },
            ],
            yaxis: [
              {
                y: weeklyPassengersHigh,
                strokeDashArray: 4,
                borderColor: palette.amblea.blue[600],
                label: {
                  borderColor: palette.amblea.blue[600],
                  position: 'left',
                  textAnchor: 'start',
                  offsetY: 6,
                  offsetX: 6,
                  style: {
                    color: palette.amblea.white,
                    background: palette.amblea.blue[600],
                  },
                  text: weeklyPassengersHigh.toString(),
                },
              },
            ],
            points,
          },
        }}
        series={[{name: label, data: values}]}
        type="line"
        width="100%"
        height={240}
      />
    </ChartRoot>
  )
}
