import {palette, Header, Andiv, Text, ArrowRightIcon, Loader} from '@ambler/andive-next'
import React from 'react'
import {VSpace} from '@ambler/andive'
import gql from 'graphql-tag'

import styled from 'styled-components'
import Link from 'next/link'

import moment from '@ambler/moment'
import {omit, sortBy} from 'lodash'
import {Title} from '../components/bo-andive'
import {AppContainer, useMobile} from '../components/stack/responsive'
import {BarChart, LineChart} from '../components/metrics/chart'
import {useVisibleQuery} from '../hooks/use-visible-query'
import {ErrorCard} from '../components/error'
import {withApollo} from '../components/stack/apollo/with-apollo'
import {QueryStatus, useQueryStatus} from '../hooks/use-query-status'
import {HeaderBurgerButton, PageContent, PageDrawer, PageHeader, PageLayout} from '../components/lab/page-layout'
import {TcMetricQuery_} from './index.generated'

const tcMetricQuery = gql`
  query TcMetricQuery {
    metrics: trafficControlMetric {
      id
      missionsToDispatch
      dailyBookedPassengers
      dailyScheduledPassengers
      weeklyBookedPassengers
      weeklyMetrics {
        id
        week
        year
        bookedPassengers
      }
      updatedAt
    }
  }
`

const Card = styled(Andiv).attrs({
  border: `1px solid ${palette.amblea.grey[400]}`,
  borderRadius: '4px',
  p: '16px',
})``

const ReversableAndiv = styled.div<{$reversed: boolean; $column: boolean; $gap: string}>`
  display: flex;
  flex-direction: ${props => {
    if (props.$reversed) {
      return props.$column ? 'column-reverse' : 'row-reverse'
    } else {
      return props.$column ? 'column' : 'row'
    }
  }};
  gap: ${props => props.$gap};
`

function DailyMetric({
  label,
  value,
  bg,
  fg,
  rightIcon = null,
}: {
  label: string
  value: number
  bg: string
  fg: string
  rightIcon?: JSX.Element
}) {
  return (
    <Andiv p="16px" borderRadius="4px" bg={bg} justify="space-between" align="center" style={{height: '114px'}}>
      <Andiv column gap="16px">
        <Text color={fg}>
          <b>{label}</b>
        </Text>
        {value !== undefined ? (
          <Text color={fg} style={{fontSize: '42px', lineHeight: '42px'}}>
            {value}
          </Text>
        ) : (
          <div style={{height: '42px'}}>
            <Loader />
          </div>
        )}
      </Andiv>
      {rightIcon}
    </Andiv>
  )
}

const translateDays = {
  Mon: 'Lun',
  Tue: 'Mar',
  Wed: 'Mer',
  Thu: 'Jeu',
  Fri: 'Ven',
  Sat: 'Sam',
  Sun: 'Dim',
}

function WeeklyMetric({values, lastWeekCount}: {values: Record<string, number>; lastWeekCount: number}) {
  const weekDayData = omit(values, ['Sam', 'Dim'])
  const weekDayTotal = Object.values(weekDayData).reduce((sum, x) => sum + x)
  const weekDayAverage = Math.round(weekDayTotal / 5)

  const total = Object.values(values).reduce((sum, x) => sum + x)
  const average = Math.round(total / 7)
  const trend = total < lastWeekCount ? '📉' : '📈'

  const label = 'Passengers confirmés (par date de création)'

  const translatedValues = Object.keys(values)
    .reverse()
    .reduce((acc, key) => {
      // @ts-ignore FixType
      return {...acc, [translateDays[key]]: values[key]}
    }, {})

  return (
    <Card bg="white" column flex="1">
      <Andiv gap="8px" column>
        <Text t="body2" color={palette.amblea.blue[800]}>
          {label}
        </Text>
        <Text t="body2" color={palette.amblea.grey[600]}>{`Total: ${total} ${
          total !== lastWeekCount ? trend : ''
        } | Moyenne: ${average} | Moyenne jours ouvrés: ${weekDayAverage}`}</Text>
        <BarChart values={translatedValues} label={label} average5={weekDayAverage} average7={average} />
      </Andiv>
    </Card>
  )
}

function Home() {
  const isMobile = useMobile()
  const queryResult = useVisibleQuery<TcMetricQuery_>(tcMetricQuery)

  const queryStatus = useQueryStatus({queryResult})

  const {
    dailyBookedPassengers,
    dailyScheduledPassengers,
    missionsToDispatch,
    weeklyBookedPassengers,
    weeklyMetrics,
    updatedAt,
  } = queryResult.data?.metrics || {}

  const lastUpdate = moment(updatedAt).fromNow()

  const [sortedWeeklyMetrics, setSortedWeeklyMetrics] = React.useState([])

  const loadingPunchlines = [
    'TC au poste, armement des casques audio 🎧',
    '📞 Préchauffage de Ringover',
    'Téléchargement du record  de passagers 📈',
    '📊 Création du diagramme de la semaine',
    "Chargement de l'algorithme de dispatch 🚑",
    '🔁 Rotation du tour de rôle',
    '🤭 Sois sérieux sans te prendre au sérieux',
    '❤️‍🔥 Sois passionné, passionnant et surtout toi-même',
    '🤝 Parle souvent, contredis parfois, rallie-toi tout le temps',
    "🫡 Tu fais partie d'une équipe commando",
    '🙊 Délivre plus que tu ne promets',
  ]

  React.useEffect(() => {
    if (!weeklyMetrics) {
      return
    }
    // ? fake data from 2018; from weeks 29 to 43
    const sorted = sortBy(
      [
        ...[...new Array(15).keys()]
          .map(i => i + 27)
          .map(i => ({
            id: `${i}_fake_2018`,
            week: i + 1,
            year: 2018,
            bookedPassengers: 0,
          })),
        ...weeklyMetrics,
      ],
      ['year', 'week'],
    )
    setSortedWeeklyMetrics(sorted)
  }, [weeklyMetrics])

  return (
    <>
      <PageLayout hasDrawer $row>
        <PageDrawer />
        <PageContent>
          <PageLayout $column>
            <PageHeader>
              <Header left={<HeaderBurgerButton />} center={<Title>Tableau de bord</Title>} />
            </PageHeader>
            <PageContent>
              <VSpace $px={32} />
              <AppContainer>
                {queryStatus === QueryStatus.ERROR && <ErrorCard error={queryResult.error} />}

                <Andiv column gap="16px">
                  <ReversableAndiv $column={isMobile} $reversed={isMobile} $gap="16px">
                    <Andiv flex="2">
                      {weeklyBookedPassengers ? (
                        <WeeklyMetric
                          values={weeklyBookedPassengers}
                          lastWeekCount={sortedWeeklyMetrics?.[sortedWeeklyMetrics.length - 1]?.bookedPassengers}
                        />
                      ) : (
                        <Card
                          bg="white"
                          minWidth="100%"
                          align="center"
                          justify="center"
                          column
                          gap="16px"
                          style={{height: '374px'}}
                        >
                          <Loader />
                          <Text>{loadingPunchlines[Math.floor(Math.random() * loadingPunchlines.length)]}</Text>
                        </Card>
                      )}
                    </Andiv>

                    <Andiv flex="1" column justify="space-between" gap="16px">
                      <Link href="/tasks">
                        <a style={{display: 'block', width: '100%'}}>
                          <DailyMetric
                            label="Missions à dispatcher"
                            value={missionsToDispatch}
                            rightIcon={<ArrowRightIcon color={palette.amblea.pink[800]} />}
                            bg={palette.amblea.pink[600]}
                            fg={palette.amblea.pink[800]}
                          />
                        </a>
                      </Link>
                      <DailyMetric
                        label="Passengers confirmés aujourd'hui"
                        value={dailyBookedPassengers}
                        bg={palette.amblea.tea[600]}
                        fg={palette.amblea.white}
                      />
                      <DailyMetric
                        label="Passengers prévus aujourd'hui"
                        value={dailyScheduledPassengers}
                        bg={palette.amblea.azure[600]}
                        fg={palette.amblea.azure[800]}
                      />
                    </Andiv>
                  </ReversableAndiv>

                  <Andiv column>
                    {!isMobile && sortedWeeklyMetrics ? (
                      <Card bg="white" column>
                        <Text t="body2" color={palette.amblea.blue[800]}>
                          Historique Amblea
                        </Text>

                        <VSpace $px={16} />
                        <LineChart
                          label="Passengers"
                          categories={sortedWeeklyMetrics.map(
                            weeklyMetric => `${weeklyMetric.year} semaine ${weeklyMetric.week}`,
                          )}
                          values={sortedWeeklyMetrics.map(weeklyMetric => weeklyMetric.bookedPassengers)}
                        />
                      </Card>
                    ) : null}
                    <Andiv p="8px 0" justify="flex-end">
                      <Text t="body2" color={palette.amblea.grey[600]}>{`Dernière mise à jour: ${lastUpdate}`}</Text>
                    </Andiv>
                  </Andiv>
                </Andiv>
              </AppContainer>
              <VSpace $px={32} />
            </PageContent>
          </PageLayout>
        </PageContent>
      </PageLayout>
    </>
  )
}

export default withApollo(Home)
