import React from 'react'
import type {QueryHookOptions, DocumentNode, OperationVariables} from '@apollo/client'
import {useQuery, NetworkStatus} from '@apollo/client'

import type {FixType} from '@ambler/shared'
import {assertUnreachable} from '@ambler/shared'

import ms from 'ms'
import moment from '@ambler/moment'
import {useAsideLoader} from '../components/aside-loader'
import useEventListener from './use-event-listener'

enum LoadingState {
  Initial = 'Initial',
  UserIntent = 'UserIntent',
  Refetch = 'Refetch',
  NotLoading = 'NotLoading',
}
const useLoadingState = (networkStatus: NetworkStatus) => {
  if (!networkStatus) {
    return LoadingState.NotLoading
  }

  switch (true) {
    case networkStatus === NetworkStatus.loading:
      return LoadingState.Initial
    case networkStatus === NetworkStatus.setVariables:
      return LoadingState.UserIntent
    case networkStatus === NetworkStatus.refetch:
      return LoadingState.Refetch
    default:
      return LoadingState.NotLoading
  }
}

export const VisibleQueryResult = ({networkStatus, initialState, emptyState, isEmpty, children}: FixType) => {
  const loadingState = useLoadingState(networkStatus)

  if (loadingState === LoadingState.Initial) {
    return initialState
  }

  if (loadingState === LoadingState.NotLoading || loadingState === LoadingState.Refetch) {
    return isEmpty ? emptyState : children
  }

  if (loadingState === LoadingState.UserIntent) {
    return initialState
  }

  assertUnreachable(loadingState)
}

function useVisibleQuery_<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
) {
  const [hidden, setHidden] = React.useState(document.visibilityState === 'hidden')
  const [hiddenAt, setHiddenAt] = React.useState(moment())

  const enhancedOptions = React.useMemo<QueryHookOptions<TData, TVariables>>(
    () =>
      options
        ? {
            ...options,
            // TODO: April 22nd 2022: we moved from react-apollo to @apollo/client
            // TODO: check if this is still true
            // ? There seems to be an issue with the `skip` prop, see: https://github.com/apollographql/react-apollo/issues/3492.
            // ? In the issue, the comment https://github.com/apollographql/react-apollo/issues/3492#issuecomment-567454891 says it works,
            // ? but I have not succeeded in making it work too.
            // ?
            // ? 👉 Whatever, we now that pollInterval = 0 always works (from the doc):
            // ? > Note that if you set pollInterval to 0, the query will not poll.
            // ?
            // ? Note: It would be nice to check in isolation (codesandbox ?) if without interference and with
            // ? the latest version of @apollo/react-hooks, the skip prop works.
            pollInterval: options.skip || hidden ? 0 : options.pollInterval,
            notifyOnNetworkStatusChange: true,
          }
        : undefined,
    [hidden, options],
  )

  const result = useQuery<TData, TVariables>(query, enhancedOptions)

  const [isRefetch, setIsRefetch] = React.useState(false)
  useAsideLoader(isRefetch)

  const onVisibilityChange = React.useCallback(() => {
    const willHide = document.visibilityState === 'hidden'

    if (!willHide) {
      const intervalToRefetch = options?.pollInterval ? options.pollInterval / 2 : ms('7s')
      const mustRefetch = options && !options.skip ? moment().subtract(intervalToRefetch, 'ms') > hiddenAt : false

      if (mustRefetch) {
        setIsRefetch(true)
        result
          .refetch()
          .then(() => setIsRefetch(false))
          .catch(() => setIsRefetch(false))
      }
    } else {
      setHiddenAt(moment())
    }
    setHidden(willHide)
  }, [hiddenAt, options, result])

  // ? Hook to the DOM visibilitychange event.
  useEventListener('visibilitychange', onVisibilityChange)

  return result
}

export const useVisibleQuery = process.env.FEAT_VISIBLE_QUERY ? useVisibleQuery_ : useQuery
